@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.fotoFundoPreCadastro_fvewqlefhjgvbAUTH {
    width: 20%;
    height: 100vh;
    padding: 0 !important;

    background: url('./../../../assets/imagens/BANNER_DESKTOP.png') 65% 40%;
    background-size: cover;
}

.font-personalizadaAUTH {
    font-family: 'Roboto', sans-serif !important;
}

.cssformPreCadastro_cewolvbCOLibeofrqaAUTH {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
}

.divFormulario_cewolvbCOLibeofrqaAUTH {
    display: flex;
    flex-direction: column;
    margin: 30px;
    margin-top: 0px;
    padding: 25px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.divFormularioWitdhResponsivo_cewolvbCOLibeofrqaAUTH {
    width: 40%;
}


.divImgsMobile_frhvbqwyuofvbqwfAUTH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.imagemLogo_cewolvbCOLibeofrqaAUTH {
    width: 139px;
    margin-right: 20px;
}
.imagemLogoSP_cewolvbCOLibeofrqaAUTH {
    width: 104px;
    margin-left: 20px;
}

.break-word {
    word-wrap: break-word; /* Quebra a palavra quando necessário */
    word-break: break-word; /* Força a quebra em qualquer parte */
    overflow-wrap: break-word; /* Compatibilidade adicional */
}

.cursorClick {
    cursor: pointer;
}

.fontSizeTitle_efcwhfgkvqweAUTH {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.fontSizeAUTH {
    font-size: 1.2rem;
}

.fontSizeh4AUTH {
    font-size: 0.8rem;
}

@media only screen and (max-width: 576px) {
    .fotoFundoPreCadastro_fvewqlefhjgvbAUTH {
        /* display: none; */
    }

    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqaAUTH {
        width: 96% !important;
    }

    .quebraDeLinhaSpan {
        display: block;
    }

    .fontSizeAUTH {
        font-size: 16px;
    }

    .marginBottomResponsivo {
        margin-bottom: 20px;
    }

    .fontSizeTitle_efcwhfgkvqweAUTH {
        font-size: 22px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqaAUTH {
        width: 86% !important;
    }

    .marginBottomResponsivo {
        margin-bottom: 20px;
    }

    .fontSizeTitle_efcwhfgkvqweAUTH {
        font-size: 22px;
        margin-bottom: 10px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqaAUTH {
        width: 76% !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqaAUTH {
        width: 66% !important;
    }
}
