@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


.fotoFundoPreCadastro_fvewqlefhjgvb {
    width: 20%;
    height: 100vh;
    padding: 0 !important;

    background: url('./../../assets/imagens/BANNER_DESKTOP.png') 65% 40%;
    background-size: cover;
}

.font-personalizada {
    font-family: 'Raleway', sans-serif !important;
}

.font-personalizadaTwo {
    font-family: 'Roboto', sans-serif !important;
}

.font-title_ewfkjhvqwrwegf {
    font-size: 3.5rem;
}

.largura-title_ewfkjhvqwrwegfa {
    width: 37%;
    margin-left: 20px;
    margin-right: 20px;
}

.inputsLimitesETaxas {
    /* font-size: 24px !important; */
    border-radius: 25px !important;
}

.inputsLimitesETaxas input::placeholder {
    color: #848484;

}

.selectLimitesETaxas .ant-select-selector {
    border-radius: 25px !important;
}

.selectLimitesETaxas .ant-select-selector .ant-select-selection-placeholder {
    color: #848484 !important;
}

.selectLimitesETaxas .ant-select-selector .ant-select-selection-placeholder::placeholder {
    color: #848484 !important;
}

.cssformPreCadastro_cewolvbCOLibeofrqa {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;

}

.divFormulario_cewolvbCOLibeofrqa {
    display: flex;
    flex-direction: column;
    margin: 30px;
    margin-top: 0px;
    padding: 30px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

.divFormularioWitdhResponsivo_cewolvbCOLibeofrqa {
    width: 25%;
}

.divFormularioWitdhResponsivoMSG_cewolvbCOLibeofrqa {
    width: 37%;
}

.divTitleAndForm_fewkhfgvqfioyu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.imagemLogo_cewolvbCOLibeofrqa {
    width: 139px;
    margin-right: 20px;
}

.imagemLogoSP_cewolvbCOLibeofrqa {
    width: 104px;
    margin-left: 20px;
}

.divImgsMobile_frhvbqwyuofvbqwf {
    display: none;
}

.divImgsMobileSempre_frhvbqwyuofvbqwf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.divImgsDesktop_frhvbqwyuofvbqwf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
}

.fontSizeTitleLimitesETaxas_efcwhfgkvqwe {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.fontSizeLimitesETaxas {
    font-size: 1.2rem;
}

@media only screen and (max-width: 576px) {
    .fotoFundoPreCadastro_fvewqlefhjgvb {
        /* display: none; */
    }

    .divFormularioWitdhResponsivoMSG_cewolvbCOLibeofrqa {
        width: 90% !important;
        margin-top: 20px;
    }

    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqa {
        width: 90% !important;
        margin-top: 20px;
    }

    .divFormulario_cewolvbCOLibeofrqa {
        padding: 20px;
    }

    .fontSizeLimitesETaxas {
        font-size: 16px;
    }

    .fontSizeTitleLimitesETaxas_efcwhfgkvqwe {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .fontSizeTitleLimitesETaxasPrincipal_efcwhfgkvqwe {
        font-size: 22px;
        margin-bottom: 10px;
    }

}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .divFormularioWitdhResponsivoMSG_cewolvbCOLibeofrqa {
        width: 86% !important;
        margin-top: 20px;
    }

    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqa {
        width: 86% !important;
        margin-top: 20px;
    }

    .divFormulario_cewolvbCOLibeofrqa {
        padding: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .divFormularioWitdhResponsivoMSG_cewolvbCOLibeofrqa {
        width: 76% !important;
        margin-top: 20px;
    }

    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqa {
        width: 76% !important;
        margin-top: 20px;
    }

    .divFormulario_cewolvbCOLibeofrqa {
        padding: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .divFormularioWitdhResponsivoMSG_cewolvbCOLibeofrqa {
        width: 66% !important;
    }

    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqa {
        width: 66% !important;
    }

    .largura-title_ewfkjhvqwrwegfa {
        width: 100%;
    }

    .divFormulario_cewolvbCOLibeofrqa {
        padding: 20px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {

    .divFormularioWitdhResponsivo_cewolvbCOLibeofrqa {
        width: 28% !important;
    }

    .divFormulario_cewolvbCOLibeofrqa {
        padding: 20px;
    }
}



/* tablet and mobile */

@media only screen and (min-width: 100px) and (max-width: 992px) {

    .divTitleAndForm_fewkhfgvqfioyu {
        flex-direction: column;
        justify-content: center;
    }

    .largura-title_ewfkjhvqwrwegfa {
        width: 97%;
        text-align: center;
    }

    .font-title_ewfkjhvqwrwegf {
        font-size: 1.8rem;
    }

    .divImgsDesktop_frhvbqwyuofvbqwf {
        display: none;
    }

    .divImgsMobile_frhvbqwyuofvbqwf {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
