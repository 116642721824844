.fotoFundoLogin {
    width: 20%;
    height: 100vh;
    /* object-fit: contain; */
    /* height: 100vh; */
    background: url('./../../assets/imagens/ponteSp.jpg') 25% 40%;
    background-size: cover;
}

@media only screen and (max-width: 576px) {
    .fotoFundoLogin {
        display: none;
    }
}
