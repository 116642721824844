/* Configuracoes de cores estão na pasta css */

.RelatorioConsolidado_Table_dnlowefo {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.RelatorioConsolidado_Table_dnlowefo tbody td,
.RelatorioConsolidado_Table_dnlowefo tbody th {
    padding: 6px;
    text-align: center;
}

.RelatorioConsolidado_Table_dnlowefo thead th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    text-align: center;
    /* background-color: #04AA6D; */
    /* color: white; */
}

.RelatorioConsolidado_Botoes_dewgfywefv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.RelatorioConsolidado_botaoGerarPDF_djbewofuygvb {
    display: flex !important;
    justify-content: flex-end;
}

@media only screen and (max-width: 576px) {
    .RelatorioConsolidado_Botoes_dewgfywefv {
        flex-direction: column;
    }

    .RelatorioConsolidado_botaoGerarPDF_djbewofuygvb {
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
}
