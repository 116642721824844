div div .dspclassMenuPopUpDraweer {
    display: none !important;
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.logoimg {
    margin: 15px;
    width: 200px;
}

.logoimgMenuResponsive {
    width: 200px;
}

.logoimgFooter {
    width: 100px;
    margin-left: 5px;
}

.iconePGimg {
    margin: 10px 0 15px 20px;
    width: 50px;
}

.divTitle {
    float: left;
    margin: 15px;
}

.dropDownMenu {
    float: right !important;
    margin-right: 10px !important;
    height: 100% !important;
}

.siteLayout {
    margin-left: 20em;
}

.siteLayout2 {
    margin-left: 5.5em;
}

.siteLayoutMobile {
    margin-left: 0;
}

.siteLayoutBackground {
    margin: 10px;
    margin-top: 70px;
    padding: 24px;
    min-height: 160px;
}

.DraerwMenuPrinmcipal {
    width: calc(100% + 48px) !important;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
    width: 376px !important;
}

@media only screen and (max-width: 768px) {
    .siteLayout2 {
        margin-left: 0px;
    }

    .site-layout-sub-header-background {
        width: 100% !important;
    }
}

@media only screen and (max-width: 576px) {

    .ant-drawer-left .ant-drawer-content-wrapper,
    .ant-drawer-right .ant-drawer-content-wrapper {
        width: 276px !important;
    }
}
