.divCard_CardSituacao_ohjgvbferwfyt {
    width: auto;
}


@media only screen and (max-width: 480px) {

    .divCard_CardSituacao_ohjgvbferwfyt {
        width: 100%;
    }

}
