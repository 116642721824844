.ant-tabs-card.ant-tabs-top.tab-municipios>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top.tab-municipios>div>.ant-tabs-nav .ant-tabs-tab-active {
    border-style: solid;
    border-bottom-color: transparent !important;
    border-bottom-width: 0px !important;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #1890ff;
}
