.botaoVerdeScreenCongelamento {
    color: #fff;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.botaoVerdeScreenCongelamento:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.botaoVerdeScreenCongelamento:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.botaoVerdeScreenCongelamento:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.Congelamento_divSelectButton_cewlhfbweu {
    display: flex;
    flex-direction: row;
}

.Congelamento_TextosStatusEDatas_asdfyukgvoe {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

@media only screen and (max-width: 576px) {
    .Congelamento_divSelectButton_cewlhfbweu {
        flex-direction: column;
    }

    .Congelamento_TextosStatusEDatas_asdfyukgvoe {
        flex-direction: column;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .Congelamento_TextosStatusEDatas_asdfyukgvoe {
        flex-direction: column;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .Congelamento_TextosStatusEDatas_asdfyukgvoe {
        flex-direction: column;
    }
}

.divBotaoAtencaoEBaixarTodas_congelamento_sdfgjrebgopib {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (min-width: 200px) and (max-width: 430px) {
    .divBotaoAtencaoEBaixarTodas_congelamento_sdfgjrebgopib {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
