.Configuracoes_BotaoVerde_edfrgjkfbrefg {
    color: #fff;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.Configuracoes_BotaoVerde_edfrgjkfbrefg:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.Configuracoes_BotaoVerde_edfrgjkfbrefg:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}
.Configuracoes_BotaoVerde_edfrgjkfbrefg:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.Configuracoes_BotaoAmarelo_dslkcjbsdcpi {
    color: #fff !important;
    background: #e08e0b !important;
    border-color: #e08e0b !important;
    background-color: #e08e0b !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.Configuracoes_BotaoAmarelo_dslkcjbsdcpi:hover {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}

.Configuracoes_BotaoAmarelo_dslkcjbsdcpi:active {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}
.Configuracoes_BotaoAmarelo_dslkcjbsdcpi:focus {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}

.ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]:hover {
    color: rgba(0,0,0,.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none !important;
    box-shadow: none !important;
}
