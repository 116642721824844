.botaoVerdeBaixarTodas {
    color: #fff;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.botaoVerdeBaixarTodas:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.botaoVerdeBaixarTodas:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.botaoVerdeBaixarTodas:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.divBotaoRegraEBaixarTodas_certidao_xcekfghvewiqf {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (min-width: 200px) and (max-width: 550px) {
    .divBotaoRegraEBaixarTodas_certidao_xcekfghvewiqf {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
@media only screen and (min-width: 200px) and (max-width: 400px) {
    .divbotoesDownload_certidao_ohufverwiqyutv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
