.modalDadasLimite_kaevdkfaed {
    width: 70% !important;
}
@media only screen and (min-width: 200px) and (max-width: 576px) {
    .modalDadasLimite_kaevdkfaed {
        width: 95% !important;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .modalDadasLimite_kaevdkfaed {
        width: 90% !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .modalDadasLimite_kaevdkfaed {
        width: 70% !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .modalDadasLimite_kaevdkfaed {
        width: 60% !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .modalDadasLimite_kaevdkfaed {
        width: 40% !important;
    }
}
@media only screen and (min-width: 1600px) {
    .modalDadasLimite_kaevdkfaed {
        width: 45% !important;
    }
}
