.Municipios_ImgPrefeito_oiufbgwefo {
    width: 130px;
}

.divtextsCards_CardPrefeito_fewohbfwo {
    width: auto;
}

.Municipios_DivPrincipalPreifeito_fdijkbsi {
    display: flex;
    height: 100%;
}

.Municipios_DivCardSituacao_fceowuyvbg {
    display: flex;
    align-items: flex-end;
    flex: 1;
}

@media only screen and (max-width: 576px) {

    .Municipios_ImgPrefeito_oiufbgwefo {
        width: 160px;
    }

    .Municipios_DivCardSituacao_fceowuyvbg {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 480px) {

    .Municipios_ImgPrefeito_oiufbgwefo {
        width: 130px;
    }

    .Municipios_DivCardSituacao_fceowuyvbg {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .divtextsCards_CardPrefeito_fewohbfwo {
        width: 100%;
    }

    .Municipios_DivPrincipalPreifeito_fdijkbsi {
        flex-direction: column;
    }
}
