.modalMunicipios_uhrgbfars {
    width: 70% !important;
}
.modalFiltrosCronograma_jkbqerwoygfoerl {
    width: 70% !important;
}
.botoesGerarXlsxEoutros_ksjhvfgh {
    display: flex;
    flex-direction: row;
}

.Cronograma_TextosStatusEDatas_kuyjfvcdtkgc {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.footerTable_lhjfvwee {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

@media only screen and (min-width: 200px) and (max-width: 700px) {
    .footerTable_lhjfvwee {
        flex-direction: column;
    }
}
@media only screen and (min-width: 200px) and (max-width: 576px) {
    .modalMunicipios_uhrgbfars {
        width: 95% !important;
    }
    .modalFiltrosCronograma_jkbqerwoygfoerl {
        width: 95% !important;
    }
    .botoesGerarXlsxEoutros_ksjhvfgh {
        display: flex;
        flex-direction: column;
    }
    .Cronograma_TextosStatusEDatas_kuyjfvcdtkgc {
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .modalMunicipios_uhrgbfars {
        width: 90% !important;
    }
    .modalFiltrosCronograma_jkbqerwoygfoerl {
        width: 80% !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .modalMunicipios_uhrgbfars {
        width: 70% !important;
    }
    .modalFiltrosCronograma_jkbqerwoygfoerl {
        width: 60% !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .modalMunicipios_uhrgbfars {
        width: 70% !important;
    }
    .modalFiltrosCronograma_jkbqerwoygfoerl {
        width: 60% !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .modalMunicipios_uhrgbfars {
        width: 70% !important;
    }
    .modalFiltrosCronograma_jkbqerwoygfoerl {
        width: 55% !important;
    }
}
@media only screen and (min-width: 1600px) {
    .modalMunicipios_uhrgbfars {
        width: 75% !important;
    }
    .modalFiltrosCronograma_jkbqerwoygfoerl {
        width: 55% !important;
    }
}
