.DadosPrefeito_divsDataHora_olbrqeoiugfyb {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 576px) {
    .DadosPrefeito_divsDataHora_olbrqeoiugfyb {
        display: flex;
        flex-direction: column;
    }
}
